// src/components/Public/AddToCartButton.js
import React, { useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import { Button } from 'react-bootstrap';

const AddToCartButton = ({ product }) => {
  const { addToCart } = useContext(CartContext);

  const handleAddToCart = () => {
    addToCart(product);
    
  };

  return (
    <Button variant="primary" onClick={handleAddToCart}>
      Add to Cart
    </Button>
  );
};

export default AddToCartButton;
