import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

const ProductSearch = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState('');

  // Update the searchTerm state when the input changes
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Redirect to the search results page with the search term as a query parameter
  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?term=${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm(''); // Optionally clear the input after submission
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <Form.Control
        type="text"
        placeholder="Search for products..."
        value={searchTerm}
        onChange={handleChange} // Removed this line to stop real-time updates
      />
    </Form>
  );
};

export default ProductSearch;
