import React from 'react';
import ProductSearch from '../components/Public/ProductSearch';
import MyNavbar from '../components/Public/MyNavbar';
import { Container, Row, Col } from 'react-bootstrap';

const PublicPage = () => {
  return (
    <div>
      <MyNavbar />
      <Container>
        <Row className="mt-4">
          <Col>
            <h1>Welcome to Our Store</h1>
            <p>Find the best products here.</p>
            <ProductSearch /> {/* Displays the search bar */}
            {/* Other content or components */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PublicPage;
