// src/components/Public/ProductList.js
import React from 'react';
import { Card} from 'react-bootstrap';
import AddToCartButton from './../Common/AddToCartButton'; // Import the AddToCartButton component

const ProductList = ({ products }) => {
  return (
    <div className="product-list">
      {products.map((product) => (
        <Card key={product.id} className="mb-3">
          <Card.Img variant="top" src={product.image} />
          <Card.Body>
            <Card.Title>{product.title}</Card.Title>
            <Card.Text>${product.price.toFixed(2)}</Card.Text>
            <AddToCartButton product={product} /> {/* Add the button here */}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default ProductList;
