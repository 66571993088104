import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProducts } from '../services/productService';
import ProductSearch from '../components/Public/ProductSearch';
import ProductList from '../components/Public/ProductList';
import Spinner from '../components/Common/Spinner';
import NoResults from '../components/Common/NoResults';
import MyNavbar from '../components/Public/MyNavbar';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const SearchResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({ category: '', priceRange: '' });
  const [sortOption, setSortOption] = useState(''); // State for sorting option

  // Extract search term from URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const term = queryParams.get('term') || '';
    setSearchTerm(term);
  }, [location.search]);

  // Fetch products data
  useEffect(() => {
    const getProducts = async () => {
      try {
        const productsData = await fetchProducts();
        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  // Filter products based on the search term and filters
  useEffect(() => {
    const filtered = products.filter(product =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResults(filtered);
  }, [searchTerm, products]);

  // Apply filters
  const applyFilters = (products) => {
    return products.filter(product => {
      return (
        (!filters.category || product.category === filters.category) &&
        (!filters.priceRange || (product.price >= filters.priceRange[0] && product.price <= filters.priceRange[1]))
      );
    });
  };

  // Sort products based on the selected option
  const sortProducts = (products) => {
    switch (sortOption) {
      case 'priceAsc':
        return [...products].sort((a, b) => a.price - b.price);
      case 'priceDesc':
        return [...products].sort((a, b) => b.price - a.price);
      case 'titleAsc':
        return [...products].sort((a, b) => a.title.localeCompare(b.title));
      case 'titleDesc':
        return [...products].sort((a, b) => b.title.localeCompare(a.title));
      default:
        return products;
    }
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  // Handle reset filters
  const handleResetFilters = () => {
    setFilters({ category: '', priceRange: '' });
  };

  // Handle clear search term
  const handleClearSearch = () => {
    setSearchTerm('');
    navigate('/search'); // Redirect to base search page
  };

  // Handle sorting option change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  return (
    <div>
      <MyNavbar />
      <Container>
        <Row className="mt-4">
          <Col md={3}>
            <h5>Filters</h5>
            <Form>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                >
                  <option value="">All Categories</option>
                  <option value="electronics">Electronics</option>
                  <option value="clothing">Clothing</option>
                  {/* Add more categories as needed */}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Price Range</Form.Label>
                <Form.Control
                  type="text"
                  name="priceRange"
                  placeholder="e.g., 10-100"
                  value={filters.priceRange}
                  onChange={(e) => setFilters({ ...filters, priceRange: e.target.value.split('-').map(Number) })}
                />
              </Form.Group>
              <Button variant="primary" className="mt-3" onClick={handleResetFilters}>
                Reset Filters
              </Button>
            </Form>
          </Col>
          <Col md={9}>
            <ProductSearch searchTerm={searchTerm} />
            <h2 className="mt-4">Search Results for: <span className="text-primary">{searchTerm}</span></h2>
            {searchTerm && (
              <Button variant="danger" className="mt-2" onClick={handleClearSearch}>
                Clear Search Term
              </Button>
            )}
            {/* Sort By Dropdown */}
            <Form.Group className="mt-3">
              <Form.Label>Sort By</Form.Label>
              <Form.Control
                as="select"
                value={sortOption}
                onChange={handleSortChange}
              >
                <option value="">Default</option>
                <option value="priceAsc">Price: Low to High</option>
                <option value="priceDesc">Price: High to Low</option>
                <option value="titleAsc">Title: A to Z</option>
                <option value="titleDesc">Title: Z to A</option>
              </Form.Control>
            </Form.Group>
            {loading ? (
              <Spinner />
            ) : results.length === 0 ? (
              <NoResults />
            ) : (
              <ProductList products={sortProducts(applyFilters(results))} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SearchResultsPage;
