// src/pages/DashboardPage.js
import React from 'react';
import useAuthRedirect from '../hooks/useAuthRedirect';
import Dashboard from '../components/Dashboard/Dashboard';

const DashboardPage = () => {
  const user = useAuthRedirect();

  return (
    <div>
      <h2>Dashboard</h2>
      {user ? (
        <Dashboard />
      ) : (
        <p>You need to be logged in to view this page.</p>
      )}
    </div>
  );
};

export default DashboardPage;
