// src/context/CartContext.js
import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  
  const addToCart = (product) => {
    setCart(prevCart => [...prevCart, product]);
    localStorage.setItem('cartCount', cart.length + 1);

  };

  const getCartCount = () => {
    
    var count = localStorage.getItem("cartCount");
    //console.log(count);
    return count;
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, getCartCount }}>
      {children}
    </CartContext.Provider>
  );
};
