// src/services/authService.js
import axios from 'axios';

const API_URL = 'https://auth.ovisglobal.com/api/account';
const APPLICATION_ID = '4619fd47-e99a-eb11-9466-00155d512601'; // Replace with your actual application ID

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'applicationId': APPLICATION_ID,
  },
});

export const loginService = async (email, password) => {
  const response = await apiClient.post('/login', { email, password });
  return response.data;
};

export const registerService = async (email, password) => {
  const response = await apiClient.post('/register', { email, password });
  return response.data;
};

export const logoutService = async () => {
  localStorage.removeItem('user');
};

export const getCurrentUser = () => {
  var user = JSON.parse(localStorage.getItem('user'));
  return user;
};
