// src/components/Dashboard/Dashboard.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const Dashboard = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <div>
      <h1>Welcome to the Dashboard</h1>
      {/* Add more dashboard content here */}
      <button onClick={handleLogoutClick}>Logout</button>
    </div>
  );
};

export default Dashboard;
