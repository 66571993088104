// src/context/AuthContext.js
import React, { createContext, useState } from 'react';
import { logoutService, loginService, registerService, getCurrentUser } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getCurrentUser());

  const login = async (email, password) => {
    const userData = await loginService(email, password);
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const register = async (email, password) => {
    const userData = await registerService(email, password);
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const logout = async () => {
    await logoutService();
    localStorage.removeItem('user');
    setUser(null);
  };
  
  const isAuthenticated = !!user; 

  return (
    <AuthContext.Provider value={{ user, login, register, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
